import { CalendarIcon, ClockIcon } from '@/components/SvgIcons';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import {
  DateField,
  DateFieldProps,
  DatePicker,
  DatePickerProps,
  TimeField,
  TimeFieldProps,
  TimePicker,
  TimePickerProps,
} from '@mui/x-date-pickers';
import { useState } from 'react';

export function DateFieldCustom(props: DateFieldProps<any>) {
  const { InputProps, ...otherProps } = props;

  return (
    <StyledDateField
      InputProps={{
        ...InputProps,
        onPaste: (e: any) => {
          e.preventDefault();
        },
        onBlur: (e: any) => {
          e.preventDefault();
        },
        startAdornment: (
          <InputAdornment position="start">
            <CalendarIcon />
          </InputAdornment>
        ),
      }}
      {...otherProps}
    />
  );
}

export function DatePickerCustom(
  props: DatePickerProps<any> & {
    helperText?: string;
    variant?: 'filled';
  },
) {
  const { helperText, variant, ...otherProps } = props;
  const [open, setOpen] = useState<boolean>(false);

  return (
    <StyledDatePicker
      {...otherProps}
      open={open}
      onClose={() => setOpen(false)}
      slotProps={{
        field: {
          readOnly: true,
        },
        textField: {
          variant: variant ? variant : 'outlined',
          onClick: () => setOpen(true),
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">
                <CalendarIcon />
              </InputAdornment>
            ),
          },
          helperText: helperText,
        },
        openPickerButton: {
          disabled: true,
          style: { display: 'none' },
        },
      }}
    />
  );
}

export function TimeFieldCustom(props: TimeFieldProps<any>) {
  const { InputProps, variant, ...otherProps } = props;

  return (
    <StyledTimeField
      InputProps={{
        ...InputProps,
        startAdornment: (
          <InputAdornment position="start">
            <ClockIcon />
          </InputAdornment>
        ),
      }}
      {...otherProps}
    />
  );
}

export function TimePickerCustom(
  props: TimePickerProps<any> & {
    helperText?: string;
    variant?: 'filled';
    error?: boolean;
  },
) {
  const { helperText, variant, error, ...otherProps } = props;
  const [open, setOpen] = useState<boolean>(false);

  return (
    <StyledTimePicker
      {...otherProps}
      open={open}
      onClose={() => setOpen(false)}
      slotProps={{
        field: {
          readOnly: true,
        },
        textField: {
          variant: variant ? variant : 'outlined',
          onClick: () => setOpen(true),
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">
                <ClockIcon />
              </InputAdornment>
            ),
          },
          helperText: helperText,
          error: error,
        },
        openPickerButton: {
          disabled: true,
          style: { display: 'none' },
        },
      }}
    />
  );
}

const styleDateTime = {
  width: '100%',
  height: 'auto',

  '.MuiOutlinedInput-root': {
    height: '48px',
    paddingLeft: '16px',
    fontSize: '14px',
    lineHeight: '16px',

    '&.Mui-focused': {
      '.MuiInputAdornment-root': {
        '& svg': {
          //   color: '#D5A239',
        },
      },
    },

    '.MuiInputAdornment-root': {
      margin: '0px',

      '& svg': {
        height: '20px',
        width: '20px',
      },
    },

    input: {
      height: '100%',
      padding: '0px 16px 0px 12px',

      '&::placeholder': {},
    },

    fieldset: {
      borderRadius: '8px',
    },
  },
};

const StyledDateField = styled(DateField)(({ theme }) => ({
  ...styleDateTime,
  [theme.breakpoints.down('sm')]: {
    '.MuiOutlinedInput-root': {
      fontSize: 'unset',
      lineHeight: 'unset',
    },
  },
}));

const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  ...styleDateTime,
  [theme.breakpoints.down('sm')]: {
    '.MuiOutlinedInput-root': {
      fontSize: 'unset',
      lineHeight: 'unset',
    },
  },
  label: {
    lineHeight: 'unset',
  },

  '& .MuiFilledInput-root': {
    height: '48px',
    overflow: 'hidden',
    borderRadius: 8,
    fontSize: '14px',
    backgroundColor: theme.palette.white.main,
    border: '1px solid #c4c4c4',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:before': {
      borderBottom: 'unset',
    },
    '&:hover': {
      border: `1px solid ${theme.palette.black.main}!important`,
      backgroundColor: theme.palette.white.main,
      '&:before': {
        borderBottom: 'unset',
      },
      '&:after': {
        borderBottom: 'unset',
      },
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
      borderColor: theme.palette.primary.main,
      '&:after': {
        borderBottom: 'unset',
      },
    },
    '&.Mui-readOnly': {
      '&:hover': {
        border: `1px solid #E0E3E7`,
        backgroundColor: theme.palette.white.main,
        boxShadow: 'unset',
        '&::before': {
          border: 'unset',
        },
      },
      '&:before': {
        borderBottom: 'unset',
      },
    },
    '&.Mui-error': {
      borderColor: theme.palette.error.main,
    },
  },
}));

const StyledTimeField = styled(TimeField)(({ theme }) => ({
  ...styleDateTime,
  [theme.breakpoints.down('sm')]: {
    '.MuiOutlinedInput-root': {
      fontSize: 'unset',
      lineHeight: 'unset',
    },
  },
}));

const StyledTimePicker = styled(TimePicker)(({ theme }) => ({
  ...styleDateTime,
  [theme.breakpoints.down('sm')]: {
    '.MuiOutlinedInput-root': {
      fontSize: 'unset',
      lineHeight: 'unset',
    },
  },
  label: {
    lineHeight: 'unset',
  },

  '& .MuiFilledInput-root': {
    height: '48px',
    overflow: 'hidden',
    borderRadius: 8,
    fontSize: '14px',
    backgroundColor: theme.palette.white.main,
    border: '1px solid #c4c4c4',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:before': {
      borderBottom: 'unset',
    },
    '&:hover': {
      border: `1px solid ${theme.palette.black.main}!important`,
      backgroundColor: theme.palette.white.main,
      '&:before': {
        borderBottom: 'unset',
      },
      '&:after': {
        borderBottom: 'unset',
      },
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
      borderColor: theme.palette.primary.main,
      '&:after': {
        borderBottom: 'unset',
      },
    },
    '&.Mui-readOnly': {
      '&:hover': {
        border: `1px solid #E0E3E7`,
        backgroundColor: theme.palette.white.main,
        boxShadow: 'unset',
        '&::before': {
          border: 'unset',
        },
      },
      '&:before': {
        borderBottom: 'unset',
      },
    },
    '&.Mui-error': {
      borderColor: theme.palette.error.main,
    },
  },
}));

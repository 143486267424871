import { styled, TextField, TextFieldProps } from '@mui/material';
import React, { memo } from 'react';

const regexNumber = /^[0-9]+$/g;
const regexPhone = /^\+?[0-9]+$/g;

function InputCustom(
  props: TextFieldProps & {
    register?: any;
  },
) {
  const { register, onChange, onBlur, ...otherProps } = props;

  const onKeyPress = (e: any) => {
    if (props.type === 'number' && !(e.key <= 9 && e.key >= 0)) {
      e.preventDefault();
    }

    if (props.type === 'tel' && !(e.key <= 9 && e.key >= 0) && e.key !== '+') {
      e.preventDefault();
    }
  };

  const onPaste = (e: any) => {
    const text = e.clipboardData.getData('text/plain').replaceAll(' ', '');
    if (props.type === 'number' && !regexNumber.test(text)) {
      e.preventDefault();
    }

    if (props.type === 'tel' && !regexPhone.test(text)) {
      e.preventDefault();
    }
  };

  return (
    <StyledTextField
      {...register}
      {...otherProps}
      onChange={(e: any) => {
        onChange && onChange(e);
        register.onChange(e);
      }}
      onBlur={(e: any) => {
        onBlur && onBlur(e);
        register.onBlur(e);
      }}
      onKeyPress={onKeyPress}
      onPaste={onPaste}
      onWheel={(e: any) => e.target.blur()}
    />
  );
}

export default memo(InputCustom);

const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  height: 'auto',
  width: '100%',
  label: {
    lineHeight: 'unset',
    top: '-2px',
  },

  // css for TextField
  '.MuiOutlinedInput-root': {
    height: '48px',
    paddingLeft: '16px',
    fontSize: '14px',
    lineHeight: '16px',

    '&.Mui-focused': {
      '.MuiInputAdornment-root': {
        '& svg': {},
      },
    },

    '.MuiInputAdornment-root': {
      margin: '0px',

      '& svg': {
        height: '20px',
        width: '20px',
      },
    },

    input: {
      height: '100%',
      padding: '0px 16px 0px 12px',

      '&::placeholder': {},
    },

    fieldset: {
      borderRadius: '8px',
    },
  },

  '& .MuiFilledInput-root': {
    height: 48,
    fontWeight: 500,
    overflow: 'hidden',
    borderRadius: 8,
    backgroundColor: theme.palette.white.main,
    border: '1px solid',
    borderColor: '#c4c4c4',
    boxShadow: 'unset',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:before': {
      borderBottom: 'unset',
    },
    '&:after': {
      borderBottom: 'unset',
    },
    '&:hover': {
      border: `1px solid ${theme.palette.black.main}`,
      backgroundColor: theme.palette.white.main,

      '&:before': {
        borderBottom: 'unset!important',
      },
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-readOnly': {
      '&:hover': {
        border: `1px solid #E0E3E7`,
        backgroundColor: theme.palette.white.main,
        boxShadow: 'unset',
        '&::before': {
          border: 'unset',
        },
      },
      '&:before': {
        borderBottom: 'unset',
      },
    },
    '&.Mui-error': {
      borderColor: theme.palette.error.main,
    },
  },

  [theme.breakpoints.down('sm')]: {
    '.MuiOutlinedInput-root': {
      fontSize: 'unset',
      lineHeight: 'unset',
    },
  },
}));

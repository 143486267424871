import useStoreApp from '@/store';
import ClearIcon from '@mui/icons-material/Clear';
import Box from '@mui/material/Box';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import React, {
  ReactElement,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';

const geocoderService = { current: null };
interface IProps {
  // id of input
  idText: string;
  // icon of input
  icon?: ReactElement;
  // set parent location's data
  setOutput: React.Dispatch<React.SetStateAction<any>>;
  // set value react-hook-form
  setValue?: (val: string, options?: any) => void;
  // register of react-hook-form
  register?: any;
  // init default location
  isInitDefaultLocation?: boolean;
  // set default location's data (current location or partner location)
  setDefaultLocation?: React.Dispatch<React.SetStateAction<string>>;
  // location address when pick and confirm from map dialog
  mapValue?: string | null;
  // check reset
  isReset?: boolean;
}

function InputAutocompleteClass(props: TextFieldProps & IProps) {
  const {
    idText,
    icon,
    setOutput,
    setValue,
    register,
    isInitDefaultLocation,
    setDefaultLocation,
    mapValue,
    isReset,
    ...otherProps
  } = props;
  const { onChange, onBlur, ...otherRegister } = register;

  // autocomplete class init and defined by id
  const autoComplete = { [idText]: null };

  const { user } = useStoreApp(state => state.globalSlice);

  // list autocomplete input elements
  const inputAutocompleteList =
    document.getElementsByClassName('pac-target-input');
  // list pac container (dropdown list address) elements
  const pacContainerList = document.getElementsByClassName('pac-container');
  // text display on input
  const [inputValue, setInputValue] = useState<string>('');
  // location data which chosen by click an item of pac container
  const [place, setPlace] = useState<null | {
    location: string;
    address: string;
  }>(null);

  // handle on change of input, set text display and handle on change of register
  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    onChange(e);
  }, []);

  // handle on blur of input, set text display and handle on blur of register
  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      const pac = document.getElementById(`${idText}-pac-container`);
      if (!place) {
        setInputValue('');
        // action to smooth when click input after it has value
        if (pac?.childNodes && pac?.childNodes.length > 0) {
          setValue && setValue('', { shouldValidate: true });
        }
      }
      onBlur(e);
    },
    [place],
  );

  // handle clear data, set text display, set form value and validate, set parent location's data
  const handleClear = useCallback(() => {
    setInputValue('');
    setValue && setValue('', { shouldValidate: true });
    setPlace(null);
    setOutput(null);
  }, []);

  //  handle fetch detail of location by placeID or position <only for get current location>
  const fetchDetail = useCallback(
    (request: any) => {
      if (!geocoderService.current && (window as any).google) {
        geocoderService.current = new (window as any).google.maps.Geocoder();
      }

      if (!geocoderService.current) {
        return undefined;
      }

      (geocoderService.current as any).geocode(request, (results?: any) => {
        // set data location
        setPlace({
          location: JSON.stringify(results[0].geometry.location.toJSON()),
          address: results[0].formatted_address,
        });
        // set form value and validate
        setValue &&
          setValue(results[0].formatted_address, { shouldValidate: true });
        // set input text
        setInputValue(results[0].formatted_address);
        // set current location
        setDefaultLocation &&
          setDefaultLocation(
            JSON.stringify(results[0].geometry.location.toJSON()),
          );
        // set parent location's data
        setOutput({
          [idText]: JSON.stringify(results[0].geometry.location.toJSON()),
          [`${idText}Address`]: results[0].formatted_address,
          [`${idText}Ward`]: '',
          [`${idText}District`]: '',
          [`${idText}City`]: '',
        });
      });
    },
    [idText],
  );

  useEffect(() => {
    if (Boolean(isReset)) {
      handleClear();
    }

    if (isInitDefaultLocation) {
      if (!user || !user.address) {
        if (!navigator.geolocation) {
          console.log('Geolocation API not supported by this browser.');
          return;
        }

        navigator.geolocation.getCurrentPosition(
          values => {
            const location = {
              lat: values?.coords?.latitude,
              lng: values?.coords?.longitude,
            };

            fetchDetail({ location });
          },
          null,
          { maximumAge: 10000, timeout: 2000, enableHighAccuracy: true },
        );

        return;
      }

      const location = {
        lat: user.lat,
        lng: user.lng,
      };

      setPlace({ location: JSON.stringify(location), address: user.address });
      setValue && setValue(user.address);
      setInputValue(user.address);
      setDefaultLocation && setDefaultLocation(JSON.stringify(location));

      setOutput({
        [idText]: JSON.stringify(location),
        [`${idText}Address`]: user.address,
        [`${idText}Ward`]: '',
        [`${idText}District`]: '',
        [`${idText}City`]: '',
      });
    }
  }, [idText, isInitDefaultLocation, user, isReset]);

  // init input autocomplete class
  useEffect(() => {
    if (autoComplete[idText] === null) {
      autoComplete[idText] = new (
        window as any
      ).google.maps.places.Autocomplete(document.getElementById(`${idText}`), {
        // 15.8817076,108.3301273
        bounds: {
          east: 108.3301273 + 0.1,
          north: 15.8817076 + 0.1,
          south: 15.8817076 - 0.1,
          west: 108.3301273 - 0.1,
        },
        componentRestrictions: {
          country: 'vn',
        },
        fields: [
          'address_components',
          'place_id',
          'geometry',
          'formatted_address',
          'name',
        ],
        strictBounds: true,
      });
    }
    // handle event select address
    (autoComplete[idText] as any).addListener('place_changed', () => {
      const selected = (autoComplete[idText] as any).getPlace();
      // handle for click done or enter on mobile when not select
      if (!selected.geometry || !selected.geometry.location) {
        setInputValue('');
        setValue && setValue('', { shouldValidate: true });
        return;
      }
      // handle data, input text, input form value and validate
      setPlace({
        location: JSON.stringify(selected.geometry.location.toJSON()),
        address: selected.name,
      });
      setValue && setValue(selected.name, { shouldValidate: true });
      setInputValue(selected.name);
      setOutput({
        [idText]: JSON.stringify(selected.geometry.location.toJSON()),
        [`${idText}Address`]: selected.name,
        [`${idText}Ward`]: '',
        [`${idText}District`]: '',
        [`${idText}City`]: '',
      });
    });
  }, [idText]);

  // handle when change text after having place but not select address
  useEffect(() => {
    if (!!place && inputValue !== place.address) {
      setPlace(null);
      setOutput(null);
    }
  }, [inputValue, place]);

  // get id for container of input
  useEffect(() => {
    const inputIdList = Array.from(inputAutocompleteList).map((e: any) =>
      e.getAttribute('id'),
    );

    if (pacContainerList.length > inputAutocompleteList.length) {
      inputIdList.map((e: string) =>
        document.getElementById(`${e}-pac-container`)?.remove(),
      );
    }

    Array.from(pacContainerList).map((e: any, i: number) => {
      e.setAttribute('id', `${inputIdList[i]}-pac-container`);
    });
  }, [inputAutocompleteList.length, pacContainerList.length]);

  // set place data, set input text, input form value and validate
  useEffect(() => {
    if (
      typeof mapValue === 'string' &&
      (mapValue !== inputValue || !inputValue)
    ) {
      setPlace({ location: 'default', address: mapValue });
      setValue && setValue(mapValue, { shouldValidate: true });
      setInputValue(mapValue);
    }
  }, [mapValue]);

  return (
    <StyleWrap>
      <StyledInputComp
        id={idText}
        onChange={handleChange}
        onBlur={handleBlur}
        value={inputValue}
        {...otherRegister}
        {...otherProps}
      />
      {icon}
      <ClearIcon
        fontSize="small"
        onClick={handleClear}
        sx={{ visibility: inputValue ? 'visible' : 'hidden' }}
      />
    </StyleWrap>
  );
}

export default memo(InputAutocompleteClass);

const StyleWrap = styled(Box)(({ theme }) => ({
  width: '100%',
  position: 'relative',

  svg: {
    position: 'absolute',

    '&:nth-of-type(1)': {
      left: 16,
      top: 14,
    },

    '&:nth-of-type(2)': {
      right: 10,
      top: 14,
      color: theme.palette.black[20],
      cursor: 'pointer',
    },
  },
}));

const StyledInputComp = styled(TextField)<TextFieldProps>(({ theme }) => ({
  height: 'auto',
  width: '100%',
  label: {
    lineHeight: 'unset',
    top: '-2px',
    left: '30px',
  },

  // css for TextField
  '.MuiOutlinedInput-root': {
    height: '48px',
    fontSize: '14px',
    lineHeight: '16px',

    input: {
      height: '100%',
      padding: '0px 36px 0px 48px !important',
    },

    fieldset: {
      borderRadius: '8px',
    },
  },
  '& .MuiFilledInput-root': {
    height: 48,
    fontWeight: 500,
    overflow: 'hidden',
    borderRadius: 8,
    backgroundColor: theme.palette.white.main,
    border: '1px solid',
    borderColor: '#c4c4c4',
    boxShadow: 'unset',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    input: {
      height: '100%',
      paddingLeft: '42px',
      paddingRight: '32px',
    },
    '&:before': {
      borderBottom: 'unset',
    },
    '&:after': {
      borderBottom: 'unset',
    },
    '&:hover': {
      border: `1px solid ${theme.palette.black.main}`,
      backgroundColor: theme.palette.white.main,

      '&:before': {
        borderBottom: 'unset!important',
      },
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-readOnly': {
      '&:hover': {
        border: `1px solid #E0E3E7`,
        backgroundColor: theme.palette.white.main,
        boxShadow: 'unset',
        '&::before': {
          border: 'unset',
        },
      },
      '&:before': {
        borderBottom: 'unset',
      },
    },
    '&.Mui-error': {
      borderColor: theme.palette.error.main,
    },
  },

  [theme.breakpoints.down('sm')]: {
    '.MuiOutlinedInput-root': {
      fontSize: 'unset',
      lineHeight: 'unset',
    },
  },
}));
